import { json } from '@remix-run/node'
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  useRouteLoaderData,
  useSearchParams
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import { useEffect } from 'react'
import { getToast } from 'remix-toast'
import { toast as notify, Toaster } from 'sonner'
import { v4 } from 'uuid'
import useActiveCampaign from '~/hooks/useActiveCampaign'
import useGoogleTagManager from '~/hooks/useGoogleTagManager'
import usePostHog from '~/hooks/usePostHog'
import type { PublicEnvs } from '~/public-environment-variables'
import PublicEnvironmentVariables from '~/public-environment-variables'
import { getUserProfile } from '~/services/auth.server'
import { AbilityContext, defineAbilityFor } from '~/services/rbac'
import useMounted from '~/utils/use-mounted'
import { BecomeAMemberModal } from './components/modals/become-member-modal'
import {
  ACTIVECAMPAIGN_ID,
  ENVIRONMENT,
  GA_TAG_MANAGER_KEY,
  MAPBOX_ACCESS_TOKEN,
  SCHEDULE_BUILDER_FE_URL,
  SENTRY_ENABLED,
  SENTRY_REPLAY_ERROR_RATE,
  SENTRY_REPLAY_RATE,
  SENTRY_TRACE_RATE
} from './constants/env-variables.server'
import { useIsBot } from './context/is-bot-context'
import { cookiePolicy } from './cookies'
import errorImage from './images/error.webp'
import { CookiePolicyBanner } from './routes/fullstack-components.cookie-policy/route'
import PostHogClient, { getDistinctId } from './services/posthog.server'
import { getRoutescannerApiConfig, RoutescannerApi } from './services/routescanner-api.server'
import appStyles from './styles/app.css?url'
import type { CookiePolicy } from './cookies'
import type { LinksFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node'

polyfillCountryFlagEmojis()

export const shouldRevalidate = () => false

export const meta: MetaFunction = () => {
  return [
    { title: 'Routescanner - worldwide container shipping platform' },
    {
      name: 'description',
      content:
        'Routescanner is the first online platform that provides independent insights into the various routes for worldwide container transport, choose the best options.'
    },
    {
      name: 'keywords',
      content:
        'routescanner, container shipping routes, maritime route, route planner, Intermodal, Multimodal, shortsea connections, deepsea connections, rail connections, barge connections, shipping map'
    },
    {
      name: 'name',
      content:
        'For the best container shipping route comparison, use Routescanner to find the best connection by deepsea, feeder, rail, barge and truck.'
    }
  ]
}

export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap'
    },
    { rel: 'stylesheet', href: appStyles },
    {
      rel: 'icon',
      href: '/favicon.ico',
      type: 'image/ico'
    }
  ]
}

export async function loader({ request }: LoaderFunctionArgs) {
  const cookieHeader = request.headers.get('Cookie')
  const cookie: CookiePolicy = (await cookiePolicy.parse(cookieHeader)) || {}

  if (!cookie.RSAnalyticsId) {
    cookie.RSAnalyticsId = v4()
  }

  const config = await getRoutescannerApiConfig(request)
  const routescannerApi = new RoutescannerApi(config)
  await routescannerApi.registerUserVisit(cookie.RSAnalyticsId).catch(e => {
    console.error('error: Failed to register user visit')
  })

  const environment = ENVIRONMENT
  const distinctId = await getDistinctId(request)
  const profile = await getUserProfile(request, { allowUnauthenticated: true })

  if (environment !== 'production') {
    await PostHogClient().disable()
  } else {
    // Posthog seems to suddenly stop working after a while. Trying to "fix" this by enabling and opting in again.
    await PostHogClient().enable()
    await PostHogClient().optIn()
  }

  const { toast, headers } = await getToast(request)

  headers.append('Set-Cookie', await cookiePolicy.serialize(cookie))

  const publicEnvironmentVariables: PublicEnvs = {
    ENVIRONMENT: environment,
    MAPBOX_ACCESS_TOKEN,
    GA_TAG_MANAGER_KEY,
    ACTIVECAMPAIGN_ID,
    SCHEDULE_BUILDER_FE_URL,
    SENTRY_ENABLED,
    SENTRY_TRACE_RATE,
    SENTRY_REPLAY_RATE,
    SENTRY_REPLAY_ERROR_RATE
  }

  return json(
    {
      environment,
      profile: profile?.profile,
      distinctId,
      ENV: publicEnvironmentVariables,
      cookiePolicyAccepted: cookie.accepted,
      rsAnalyticsId: cookie.RSAnalyticsId,
      businessEmail: profile?.profile.email,
      toast
    },
    { headers }
  )
}

function App() {
  const { environment, ENV, cookiePolicyAccepted, businessEmail, distinctId, profile, rsAnalyticsId, toast } = useLoaderData<typeof loader>()
  const [searchParams] = useSearchParams()
  const isBot = useIsBot()

  // External scripts
  useGoogleTagManager({ environment, gtmTrackingId: ENV.GA_TAG_MANAGER_KEY, cookiePolicyAccepted: !!cookiePolicyAccepted })
  useActiveCampaign({ environment, activeCampaignId: ENV.ACTIVECAMPAIGN_ID, userEmail: businessEmail })
  usePostHog({ environment, profile, distinctId, analyticsId: rsAnalyticsId })

  // Show toasts
  const mounted = useMounted()
  useEffect(() => {
    if (!toast || !mounted) {
      return
    }

    const toastId = notify(toast.message, { description: toast.description })

    return () => {
      notify.dismiss(toastId)
    }
  }, [toast, mounted])

  return (
    <html lang="en" translate="no">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta property="og:title" content="Routescanner - worldwide container shipping platform" />
        <meta property="og:site_name" content="Routescanner - worldwide container shipping platform" />
        <meta
          property="og:description"
          content="Find the best door-to-door shipping routes by sea and inland. Compare options on lead time and CO₂e emissions."
        />
        <meta property="og:image" content="https://www.routescanner.com/images/routescanner-thumbnail.webp" />

        <Meta />
        <Links />
      </head>

      <body className="h-full">
        <PublicEnvironmentVariables {...ENV} />

        {searchParams.get('become-a-member') && <BecomeAMemberModal />}

        <AbilityContext.Provider value={defineAbilityFor(profile)}>
          <Outlet />
        </AbilityContext.Provider>

        {cookiePolicyAccepted === undefined && <CookiePolicyBanner />}

        <ScrollRestoration />

        {!isBot && <Scripts />}

        <Toaster
          toastOptions={{
            unstyled: true,
            classNames: {
              toast: 'bg-brand-darkest max-w-full w-96 px-6 py-4 text-sm text-white shadow-strongest flex flex-row items-center gap-x-8 rounded',
              title: 'font-bold',
              actionButton: 'self-start shrink-0 bg-transparent font-bold text-white py-1 px-3 rounded outline outline-brand-dark',
              cancelButton: 'self-start shrink-0 bg-transparent font-bold text-white py-1 px-3 rounded outline outline-brand-dark',
              icon: '-mr-2'
            }
          }}
        />

        {/* Google Tag Manager for users without JavaScript */}
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${ENV.GA_TAG_MANAGER_KEY}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      </body>
    </html>
  )
}

export default App

export function ErrorBoundary() {
  const { ENV } = useRouteLoaderData<typeof loader>('root') ?? {}

  const error = useRouteError()
  console.error(error)

  captureRemixErrorBoundaryError(error)

  return (
    <html>
      <head>
        {isRouteErrorResponse(error) && error.data.title ? <title>{error.data.title}</title> : <title>Oh no!</title>}
        <Meta />
        <Links />
      </head>
      <body>
        <PublicEnvironmentVariables {...(ENV as PublicEnvs)} />

        <div className="mx-auto flex h-screen max-w-4xl justify-center lg:items-center">
          <div className="mx-8 flex flex-col gap-4">
            <div className="flex justify-center">
              <img
                style={{
                  filter: 'hue-rotate(248deg)'
                }}
                height={200}
                width={200}
                alt="error"
                src={errorImage}
              />
            </div>

            {isRouteErrorResponse(error) && (
              <div className="text-center">
                <p className="my-2">{error.data.errorMessage ? error.data.errorMessage : error.data}</p>
              </div>
            )}

            {error instanceof Error && (
              <div className="text-center">
                <h1 className="my-2 text-2xl font-bold text-gray-800">Uh oh.. this not what you&apos;re looking for!</h1>
                <p className="my-2">{error.message}</p>
              </div>
            )}

            <Link to="" className="btn btn-primary">
              Take me back
            </Link>
          </div>
        </div>

        <Scripts />
      </body>
    </html>
  )
}
