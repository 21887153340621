import { useLocation } from '@remix-run/react'
import posthog from 'posthog-js'
import { useEffect } from 'react'
import type { ENVIRONMENT } from '~/constants/env-variables.server'
import type { getDistinctId } from '~/services/posthog.server'
import type { UserProfile } from '~/types/auth'

type Props = {
  environment: typeof ENVIRONMENT
  profile?: UserProfile | null
  distinctId: ReturnType<typeof getDistinctId>
  analyticsId: string | null
}

export default function usePostHog({ environment, profile, distinctId, analyticsId }: Props) {
  const location = useLocation()

  // Initialize posthog
  useEffect(() => {
    if (environment !== 'production') {
      return
    }

    posthog.init('phc_OFEaV0Avja5bReUvorLUfUQT4Edu3PpHSp0CNCPRkBn', {
      api_host: 'https://eu.posthog.com',
      capture_pageview: false,
      persistence: 'cookie'
    })
  }, [environment])

  // Identify the user
  useEffect(() => {
    if (!profile || !distinctId) {
      return
    }

    posthog.identify(distinctId, {
      auth0Id: profile?.id ?? null,
      organizationId: profile?.organizationId ?? null,
      organizationName: profile?.organizationName ?? null,
      isEmployee: profile?.appMetadata.isEmployee ?? null,
      analyticsId: analyticsId ?? null
    })
  }, [profile, distinctId, analyticsId])

  // Capture a page view
  useEffect(() => {
    posthog.capture('$pageview')
  }, [location.pathname])
}
